import styled from "styled-components"

export const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

export const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

export const Container = styled.section`
  width: 100%;
  height: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.activeTab === 6
      ? "100%"
      : props.hasIncompletedProducts
      ? "calc(100vh - 245px)"
      : "calc(100vh - 185px)"};
  overflow: hidden;
`

export const Footer = styled.section`
  height: 103px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

export const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

export const Badge = styled.div`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  width: ${(props) => (props.isBlock ? "180px" : "145px")};
  height: 42px;
  background: ${(props) =>
    props.bg ? props.theme.color[props.bg] : "rgba(77, 102, 245, 0.1)"};
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  color: ${(props) => (props.textColor ? props.textColor : "black")};
  cursor: pointer;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
    }
  }
`
export const IncompleteProductsModalContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 400px;
`

export const IncompleteProductsBanner = styled.div`
  height: 60px;
  width: 100%;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    color: black;
    font-size: 24px;
  }
`
